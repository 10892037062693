import { FormattedMessage } from "react-intl";
import { pxToRem } from "@getbread/dough";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import React from "react";
import { useLocale } from "../LocaleProvider";

type LanguageLinks = {
	legal: string;
	privacy: string;
	accessibility: string;
};

export const Container = styled("div")`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export const FooterLink = styled((props) => (
	<div role="listitem">
		<Link display="inline" target="_blank" rel="noopener" {...props} />
	</div>
))`
	font-size: ${pxToRem(10)};
	line-height: ${pxToRem(24)};
`;

const Separator = styled("div")`
	width: 1px;
	height: ${pxToRem(16)};
	background-color: ${({ theme }) => theme.palette.text.placeholder};
	margin: 0 var(--space-s);
`;

type LanguageLocale = "en" | "fr";

const languageLinkMappings: Record<LanguageLocale, LanguageLinks> = {
	en: {
		legal: "https://www.rbc.com/legal/",
		privacy: "https://www.rbc.com/privacysecurity/ca/index.html",
		accessibility: "https://www.rbc.com/accessibility/index.html",
	},
	fr: {
		legal: "https://www.rbc.com/conditions-dutilisation/",
		privacy: "https://www.rbc.com/rensperssecurite/ca/index.html",
		accessibility: "https://www.rbc.com/accessibilite/index.html",
	},
};

const linkFor = (locale: string, field: keyof LanguageLinks) => {
	const isLanguageLocale = (value: string | undefined): value is LanguageLocale => {
		return value === "en" || value === "fr";
	};

	const shortLocale = locale.split("-")[0];

	if (isLanguageLocale(shortLocale)) {
		return languageLinkMappings[shortLocale][field];
	}

	throw new Error(`Invalid Language Locale ${locale}`);
};

export const FooterLinks = (props: React.ComponentProps<typeof Container>) => {
	const { locale } = useLocale();
	return (
		<Container role="list" {...props}>
			<FooterLink href={linkFor(locale, "legal")} data-testid="footer-legal-link">
				<FormattedMessage defaultMessage="Legal" />
			</FooterLink>
			<Separator aria-hidden="true" />
			<FooterLink href={linkFor(locale, "privacy")} data-testid="footer-privacy-link">
				<FormattedMessage defaultMessage="Privacy & Security" />
			</FooterLink>
			<Separator aria-hidden="true" />
			<FooterLink
				href={linkFor(locale, "accessibility")}
				data-testid="footer-accessibility-link"
			>
				<FormattedMessage defaultMessage="Accessibility" />
			</FooterLink>
		</Container>
	);
};
