export const defaultFlags = {
	"enable-billing-address": false,
	"enable-enroll-in-autopay-when-pending": false,
	"enable-glba": false,
	"enable-maintenance-notification": false,
	"enable-cs-fraud-number": false,
	"mpEnableExpandedOTPSentMessage": false,
};

export type MemberPortalFlags = typeof defaultFlags;
