/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
	type DoughTheme,
	type DoughThemeOverrides,
	DoughThemeProvider,
	pxToRem,
} from "@getbread/dough";
import type { FCWithChildren } from "./api-client/resources/types/sharedTypes";

export const stdSpacing = {
	none: "0",
	xxs: pxToRem(2),
	xs: pxToRem(4),
	s: pxToRem(8),
	m: pxToRem(16),
	l: pxToRem(24),
	xl: pxToRem(32),
	xxl: pxToRem(40),
	xxxl: pxToRem(48),
};

const ThemeProvider: FCWithChildren<{ theme: DoughTheme }> = ({ theme = {}, children }) => {
	const overrides: DoughThemeOverrides = {
		breakpoints: {
			values: {
				...theme.breakpoints!.values!,
				sm: 675,
			},
		},
		components: {
			MuiButton: {
				styleOverrides: {
					// This is a type bug internal to MUI.
					// @ts-ignore
					textPrimary: theme.palette?.primary?.main,
				},
			},
			MuiCssBaseline: {
				styleOverrides: {
					html: {
						"textRendering": "optimizeSpeed",
						"-webkit-font-smoothing": "antialiased",
					},
					body: {
						"--space-xxs": stdSpacing.xxs,
						"--space-xs": stdSpacing.xs,
						"--space-s": stdSpacing.s,
						"--space-m": stdSpacing.m,
						"--space-l": stdSpacing.l,
						"--space-xl": stdSpacing.xl,
						"--space-xxl": stdSpacing.xxl,
						"--space-xxxl": stdSpacing.xxxl,

						"--shadow-s":
							"0px 1px 4px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.08)",
						"--shadow-m":
							"0px 4px 16px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.08)",
						"--shadow-l":
							"0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06)",
						"--shadow-xl":
							"0px 8px 32px 0px rgba(0, 0, 0, 0.16), 0px 0px 2px 0px rgba(0, 0, 0, 0.08)",
						"--shadow-nav":
							"0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.04)",

						"backgroundColor": theme.palette?.background?.primary,
					},
				},
			},
			MuiTab: {
				styleOverrides: {
					root: {
						textTransform: "capitalize",
					},
				},
			},
			MuiTabs: {
				styleOverrides: {
					indicator: {
						display: "none",
					},
				},
			},
			MuiFormHelperText: {
				styleOverrides: {
					root: {
						minHeight: pxToRem(24),
						marginTop: pxToRem(4),
					},
				},
			},
			MuiAccordion: {
				styleOverrides: {
					root: {
						"minHeight": stdSpacing.xxl,
						"boxShadow": "none",

						"&.Mui-expanded": {
							margin: 0,
						},

						"&.Mui-disabled": {
							backgroundColor: "#F6F9FA",
						},

						"&:before": {
							display: "none",
						},
					},
				},
			},
			MuiAccordionSummary: {
				styleOverrides: {
					root: {
						"height": stdSpacing.xxxl,
						"minHeight": stdSpacing.xxxl,
						"padding": `${stdSpacing.m} ${stdSpacing.s}`,
						"borderRadius": "2px",

						"&.Mui-expanded": {
							height: stdSpacing.xxxl,
							minHeight: stdSpacing.xxxl,
							margin: 0,
						},
					},
					content: {
						"fontSize": pxToRem(14),
						"fontWeight": 500,

						"&.Mui-expanded": {
							fontWeight: "bold",
							margin: 0,
						},
					},
				},
			},
			MuiAccordionDetails: {
				styleOverrides: {
					root: {
						flexDirection: "column",
						padding: `${stdSpacing.none} ${stdSpacing.s}`,
						margin: `${stdSpacing.s} ${stdSpacing.none}`,
					},
				},
			},
			MuiDivider: {
				styleOverrides: {
					root: {
						backgroundColor: theme.palette?.background?.accent,
					},
					light: {
						backgroundColor: theme.palette?.background?.accentLight,
					},
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						fontWeight: 400,
					},
				},
			},
			MuiRadio: {
				styleOverrides: {
					root: {
						// This is a type bug internal to MUI.
						// @ts-ignore
						"&.Mui-checked": { color: theme.palette.primary.main },
						"padding": 0,
						"&:has(:focus-visible)": {
							outline: `${pxToRem(2)} solid ${theme.palette?.colors?.focus}`,
							outlineOffset: pxToRem(2),
						},
					},
				},
			},
			MuiLink: {
				defaultProps: {
					underline: "always",
				},
			},
			MuiTextField: {
				defaultProps: {
					helperText: " ",
				},
			},
			MuiCheckbox: {
				styleOverrides: {
					root: {
						"&:has(:focus-visible)": {
							outline: `${pxToRem(2)} solid ${theme.palette?.colors?.focus}`,
							outlineOffset: pxToRem(-9),
							borderRadius: pxToRem(10),
						},
					},
				},
			},
			MuiIconButton: {
				styleOverrides: {
					root: {
						"&:focus-visible": {
							outline: `${pxToRem(2)} solid ${theme.palette?.colors?.focus}`,
							outlineOffset: pxToRem(-7),
							borderRadius: pxToRem(10),
						},
					},
				},
			},
		},
	};

	return (
		<DoughThemeProvider theme={theme} overrides={overrides}>
			{children}
		</DoughThemeProvider>
	);
};

export { ThemeProvider };
