import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { pxToRem } from "@getbread/dough";
import { styled } from "@mui/material/styles";
import { getConfig } from "../../utils/getConfig";
import { useLocalePublicPath } from "../../utils/usePublicPath";
import { useLocale } from "../LocaleProvider";

const LogoImg = styled("img")`
	max-width: ${pxToRem(220)};
`;

export const Logo = styled(({ className }: { className?: string }) => {
	const intl = useIntl();
	const { locale } = useLocale();
	const publicPath = useLocalePublicPath();
	const altText =
		getConfig("tenant") === "rome"
			? intl.formatMessage({ defaultMessage: "PayPlan by RBC logo" })
			: intl.formatMessage({ defaultMessage: "Member Portal Logo" });

	return (
		<Link to="/" className={className} data-testid="header-logo-link">
			<LogoImg src={`${publicPath}/logo.svg`} alt={altText} data-testid={`logo-${locale}`} />
		</Link>
	);
})`
	grid-column: 1/4;
`;
