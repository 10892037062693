import { jwtDecode, type JwtPayload } from "jwt-decode";

export interface BreadJwtPayload extends JwtPayload {
	buyerID: string;
}

export function propertyFromJwt<T extends keyof BreadJwtPayload>(
	token: string,
	property: T,
): BreadJwtPayload[T] | null {
	if (!token) {
		return null;
	}

	let parsed: BreadJwtPayload;
	try {
		parsed = jwtDecode<BreadJwtPayload>(token);
	} catch (e) {
		throw new Error(`Error decoding token: ${token}`);
	}

	if (!(property in parsed)) {
		throw new Error(`${property} not found in token`);
	}

	return parsed[property];
}
