import { useIsSmallScreen } from "@getbread/dough";
import { DesktopNav } from "./DesktopNav";
import { HamburgerNav } from "./HamburgerNav";

const Navigation: React.FC = () => {
	const isSmallScreen = useIsSmallScreen();

	return isSmallScreen ? <HamburgerNav /> : <DesktopNav />;
};

export { Navigation };
