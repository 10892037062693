import { styled } from "@mui/material/styles";
import { STYLE_CONSTANTS } from "../../utils/global.constants";

// Header
export const HeaderContainer = styled("header")`
	background-color: ${(props) => props.theme.palette.background.secondary};
	height: ${STYLE_CONSTANTS.nav.headerHeight};
	width: 100%;
	padding: 0 var(--space-m);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;

	@media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
		padding: 0 var(--space-l) 0 var(--space-l);
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 3;
	}
`;

export const HeaderBorder = styled("div")`
	border-bottom: 1px solid ${({ theme }) => theme.palette.background.accentLight};
	width: 100%;

	@media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
		position: fixed;
		top: calc(2 * var(--space-xxl));
		z-index: 3;
	}
`;

export const NavContainer = styled("div")`
	width: 100%;
	display: flex;
	justify-content: flex-end;

	@media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
		position: relative;
		z-index: 3;
	}
`;
