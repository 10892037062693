/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { getConfig } from "../utils/getConfig";
import type { PageName } from "./tracking";

type PointDataLayerObject = Parameters<typeof window.adobeDataLayer.push>[0];

const adobeTmsInitProps: PointDataLayerObject[] = [
	{ breadVersion: "2.0" },
	{ tenantName: getConfig("tenant") },
];
export class AdobeAnalytics {
	static eventProperties: Record<string, unknown> = {};

	static addEventProperties(properties: Record<string, unknown>): void {
		this.eventProperties = { ...this.eventProperties, ...properties };
	}

	static clearEventProperties(): void {
		this.eventProperties = {};
	}

	static identify(buyerID: string): void {
		this.addEventProperties({ buyerID });
	}

	static init(): void {
		window.adobeDataLayer = window.adobeDataLayer || [];
		adobeTmsInitProps.forEach((_) => {
			window.adobeDataLayer.push(_);
		});
	}

	static trackEvent(event: string, properties: Record<string, unknown> = {}): void {
		if (!window.adobeDataLayer) this.init();
		window.adobeDataLayer.push({
			event,
			eventInfo: { ...properties, ...this.eventProperties },
		});
	}

	static trackPageView(pageName: PageName): void {
		if (!window.adobeDataLayer) {
			this.init();
		}

		window.adobeDataLayer.push({
			event: "pageLoaded",
			page: { title: pageName },
		});
	}
}
