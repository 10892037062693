import { useCallback, useEffect, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { refresh as refreshApi } from "../../api-client/authentication";
import { useAuthentication } from "./index";

const THREE_HOURS = 1000 * 60 * 60 * 3;
const FIFTEEN_MINUTES = 1000 * 60 * 15;

/*
	JWT expires after 15mins
	If the buyer was active halfway through the JWT expiration time, refresh the token
	If the buyer had no activity after 10mins before the token expires log the user out
	If the buyer has refreshed the token continuously for 3 hours, instead of refreshing log the user out regardless of activity
 */
export const refeshTokenExpiryInterval = FIFTEEN_MINUTES;
export const maxRetries = THREE_HOURS / refeshTokenExpiryInterval;
export const idleTimerTimeout = FIFTEEN_MINUTES / 2;

const RefreshTimer = () => {
	const { loggedIn, refresh } = useAuthentication();

	const navigate = useNavigate();

	const timeoutInterval = useRef(0);
	const retries = useRef(0);

	const { isIdle, start, pause } = useIdleTimer({
		startManually: true,
		timeout: idleTimerTimeout,

		// we don't need high resolution timing so only sample events every second
		eventsThrottle: 1000,
	});

	const stopTimer = useCallback(() => {
		pause();
		clearInterval(timeoutInterval.current);
		timeoutInterval.current = 0;
	}, [pause]);

	const refreshToken = useCallback(async () => {
		const { jwt } = await refreshApi();
		refresh(jwt);
	}, [refresh]);

	const checkRefresh = useCallback(() => {
		if (isIdle() || retries.current === maxRetries) {
			stopTimer();

			navigate("/logout");
		} else {
			retries.current += 1;
			void refreshToken();
		}
	}, [refreshToken, navigate, isIdle, stopTimer]);

	useEffect(() => {
		if (loggedIn && !timeoutInterval.current) {
			start();

			timeoutInterval.current = window.setInterval(checkRefresh, refeshTokenExpiryInterval);
		} else if (!loggedIn) {
			stopTimer();
		}
	}, [checkRefresh, loggedIn, start, stopTimer]);

	useEffect(
		() => () => {
			stopTimer();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);
	return null;
};

export { RefreshTimer };
