import { getConfig } from "../utils/getConfig";

declare function decibelInsight(event: "updateUserId", value: string): void;
declare function decibelInsight(event: "startSession", isNewVisitor?: boolean): void;
declare function decibelInsight(
	event: "sendCustomDimension",
	dimension: string,
	value: string | boolean,
): void;

type DecibelInitProps = { env: string; tenantId: string };

const decibelInitPayload: DecibelInitProps = {
	env: getConfig("env"),
	tenantId: getConfig("tenant"),
};

export class Decibel {
	/**
	 * Refer to https://docs.google.com/spreadsheets/d/1cOYinSwXbXwxrjhNMDti3MCznAijJ6szcR1cMStqwIA/edit#gid=951706079
	 * for the full list of custom dimensions that we will need to send to Decibel
	 */
	static isInitialized = false;
	static init(props: DecibelInitProps): void {
		if (typeof decibelInsight === "undefined") return;

		const { tenantId, env } = props;
		const isNewVisitor = true;
		decibelInsight("startSession", isNewVisitor);

		decibelInsight("sendCustomDimension", "tenant_id", tenantId);
		decibelInsight("sendCustomDimension", "app_environment", env);
		Decibel.isInitialized = true;
	}
	static identify(userId: string, userVars: { email?: string; phone?: string }): void {
		if (typeof decibelInsight === "undefined") return;
		if (!Decibel.isInitialized) {
			Decibel.init(decibelInitPayload);
		}
		const { email, phone } = userVars;

		decibelInsight("updateUserId", userId);
		decibelInsight("sendCustomDimension", "buyer_id", userId);

		if (email) {
			decibelInsight("sendCustomDimension", "email", email);
		}
		if (phone) {
			decibelInsight("sendCustomDimension", "phone_number", phone);
		}
	}
}

export const loadDecibel = (): void => {
	/**
	 * Decibel should be initialized as soon as the window.onload event is triggered,
	 * which means that the async Adobe TMS has been downloaded along with its payload (decibel)
	 */
	if (document.readyState === "complete") {
		Decibel.init(decibelInitPayload);
		return;
	}
	window.addEventListener("load", () => {
		Decibel.init(decibelInitPayload);
	});
};
