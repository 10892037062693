import { initRumReporting, reportError, breadAllowedTracingUrls } from "@getbread/wheat-general";
import { createLogger, createSplunkLogger, type LoggerClient } from "@getbread/wheat-logger";
import { getConfig } from "../utils/getConfig";
import { getLogLevel, isProductionMode } from "../utils/importMeta";

// Common properties that are included in each log statement
type CommonProps = { buyerId: string | null; env: string };

interface PinoMergingObject extends Record<string, unknown> {
	msg?: string;
}

type LoggerMethod = (message: string | PinoMergingObject) => void;

const isPinoMergingObject = (payload: PinoMergingObject | string): payload is PinoMergingObject =>
	typeof payload !== "string";

const name = getConfig("name");
const env = getConfig("env");

export const browserLoggers = {
	splunk: {
		enabled: isProductionMode() && getConfig("usesplunkeventcollector"),
		initialize() {
			return createSplunkLogger({
				name,
				splunkEventCollectorToken: getConfig("splunkeventcollectortoken"),
				splunkEventCollectorUrl: getConfig("splunkeventcollectorurl"),
			});
		},
	},
	rum: {
		enabled: isProductionMode(),
		initialize() {
			initRumReporting({
				ddAppId: getConfig("datadogrumapplicationid"),
				ddSite: getConfig("datadogrumsite"),
				ddToken: getConfig("datadogrumclienttoken"),
				env,
				name,
				version: getConfig("version"),
				allowedTracingUrls: breadAllowedTracingUrls,
			});

			return {
				debug: () => undefined,
				error: reportError,
				info: () => undefined,
				warn: () => undefined,
			};
		},
	},
};

export class Logger implements LoggerClient {
	private logger: LoggerClient;

	// Some data that is meant to be output in every log statement. Keep this light!
	private commonProps: CommonProps;

	private log(level: keyof LoggerClient): LoggerMethod {
		return (props: string | PinoMergingObject) => {
			if (isPinoMergingObject(props)) {
				this.logger[level]({
					...this.commonProps,
					...props,
					msg: props.msg ?? "",
				});
			} else {
				this.logger[level]({
					...this.commonProps,
					msg: props,
				});
			}
		};
	}

	constructor(commonProps: CommonProps, logger?: LoggerClient) {
		this.commonProps = commonProps;
		const level = getLogLevel();

		this.logger =
			logger ??
			createLogger({
				level,
				name,
				browserLoggerClients: Object.values(browserLoggers).map(
					({ enabled, initialize }) => {
						if (!enabled) return undefined;
						return initialize();
					},
				),
				useConsoleLogging: getConfig("useconsolelogging"),
			});

		this.info = this.log("info");
		this.warn = this.log("warn");
		this.debug = this.log("debug");
		this.error = this.log("error");
	}

	// Adds common context properties
	public addCommonProperties(data: Partial<CommonProps>) {
		this.commonProps = {
			...this.commonProps,
			...data,
		};
	}

	public error: LoggerMethod;
	public warn: LoggerMethod;
	public info: LoggerMethod;
	public debug: LoggerMethod;
}

export const logger = new Logger({
	env,
	buyerId: null,
});
