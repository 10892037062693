import { Button, type DoughButtonProps } from "@getbread/dough";
import { styled } from "@mui/material/styles";

const LinkButtonComponent = styled(Button)`
	padding: 0;
	margin: 0;
	min-width: 0;
	min-height: 0;
` as typeof Button;

export const LinkButton = (props: DoughButtonProps) => (
	// eslint-disable-next-line test-selectors/button
	<LinkButtonComponent variant="text" {...props} />
);
