import { useCallback, useState } from "react";
import { Alert } from "@getbread/dough";
import { Box, type BoxProps } from "@mui/material";
import { stdSpacing } from "../global.style";

export type ErrorComponent = React.FC<BoxProps>;

export function useErrorMessage() {
	const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);

	const setError = useCallback((error: React.ReactNode) => {
		setErrorMessage(error);
	}, []);

	const clearError = useCallback(() => {
		setErrorMessage(null);
	}, []);

	const ErrorMessage: ErrorComponent = (props) =>
		errorMessage && (
			<Box marginTop={stdSpacing.l} {...props}>
				<Alert severity="error">{errorMessage}</Alert>
			</Box>
		);

	const hasError = !!errorMessage;

	return { setError, clearError, ErrorMessage, hasError };
}
