import { styled } from "@mui/material/styles";

// Creates sticky footer
const AppContainer = styled("div")`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
`;

export { AppContainer };
