import { toDate } from "date-fns-tz";
import { getConfig } from "./getConfig";

/**
 * This converts the passed in Date to the current timezone. Date strings from the API
 * are in the format 'YYYY-MM-DD' and they are parsed in the UTC timezone. This can
 * cause birthdays to be incorrect depending on which timezone the user is in,
 * so this is here to make sure birthdays are always displayed correctly.
 */
export const toCurrentTimeZone = (date: Date) => {
	return toDate(date.toISOString().substring(0, 10));
};

/**
 * This returns the passed date to to midnight in the east coast. It does not modify
 * the passed in date
 */
export const toStartOfDay = (date: Date) => {
	return toDate(`${date.toISOString().substring(0, 10)}T00:00:00.000`, {
		timeZone: getConfig("timezone"),
	});
};

/**
 * This returns the passed date set to 11:59PM in the east coast. It does not
 * modify the passed in date.
 */
export const toEndOfDay = (date: Date) => {
	return toDate(`${date.toISOString().substring(0, 10)}T23:59:59.999`, {
		timeZone: getConfig("timezone"),
	});
};

/**
 * This takes a string in the YYYY-MM-DD format, and serializes it to a Date object
 * that will be compatible with the API Clients.
 *
 * @remarks
 * API Clients will serialize Date objects set exactly to midnight UTC to 'YYYY-MM-DD'
 */
export const serializeYMDToDate = (ymdString: string) => {
	return new Date(`${ymdString}T00:00:00.000Z`);
};

/**
 * This converts seconds into minutes (rounded up)
 */
export const secondsToMinutesRoundedUp = (seconds = 0) => {
	return Math.ceil(seconds / 60);
};
