import { useEffect } from "react";
import { addGlobalContext } from "@getbread/wheat-general";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useFlags } from "launchdarkly-react-client-sdk";

declare global {
	interface Window {
		_fs_ready?: () => void;
	}
}

export const GlobalErrorContext = () => {
	const flags = useFlags();

	useEffect(() => {
		addGlobalContext({ flags });
	}, [flags]);

	return null;
};
