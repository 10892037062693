import { useEffect, useRef, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import {
	type AsyncProviderConfig,
	asyncWithLDProvider,
	useFlags,
} from "launchdarkly-react-client-sdk";
import { FullScreenCircularProgress } from "@getbread/dough";
import type { FCWithChildren } from "../../api-client/resources/types/sharedTypes";
import { getConfig } from "../../utils/getConfig";
import { DelayedRender } from "../../utils/useDelayedRender";
import { defaultFlags, type MemberPortalFlags } from "./defaultFlags";

const launchDarklyConfig: AsyncProviderConfig = {
	clientSideID: getConfig("launchdarklyclientsideid"),
	user: {
		anonymous: true,
	},
	reactOptions: {
		useCamelCaseFlagKeys: false,
	},
	flags: defaultFlags,
};

const Noop: FCWithChildren = ({ children }) => <>{children}</>;

export const LaunchDarklyProvider: FCWithChildren = ({ children }) => {
	const [loading, setLoading] = useState(true);

	const LDProvider = useRef<React.ElementType>(Noop);

	const loadProvider = async () => {
		try {
			LDProvider.current = await asyncWithLDProvider(launchDarklyConfig);
		} catch (e) {
			// if we can't connect to LD the hook will work but all flags will be `undefined`
			LDProvider.current = Noop;
		}

		setLoading(false);
	};

	useEffect(() => {
		void loadProvider();
	}, []);

	if (loading) {
		return (
			<DelayedRender delay={250}>
				<FullScreenCircularProgress />
			</DelayedRender>
		);
	}

	// eslint-disable-next-line react/jsx-pascal-case
	return <LDProvider.current>{children}</LDProvider.current>;
};

export const useFlag = <T extends keyof MemberPortalFlags>(key: T): MemberPortalFlags[T] => {
	const flags = useFlags();
	return flags[key] as MemberPortalFlags[T];
};
