import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
	createIntl,
	createIntlCache,
	type MessageFormatElement,
	RawIntlProvider,
} from "react-intl";
import { FullScreenCircularProgress, type Locale } from "@getbread/dough";
import type { FCWithChildren } from "../../api-client/resources/types/sharedTypes";
import { getConfig } from "../../utils/getConfig";
import { defaultLocale, getLocale, localeStorageKey } from "../../utils/locale";
import { setItem } from "../../utils/localStorage";
import { DelayedRender } from "../../utils/useDelayedRender";

type LocaleContextValue = {
	locale: Locale;
	changeLocale: (newLocale: Locale) => void;
};

type IntlMessages = Record<string, string> | Record<string, MessageFormatElement[]>;

const LocaleContext = createContext<LocaleContextValue | undefined>(undefined);

const intlCache = createIntlCache();

// This is a mutable, exported react-intl constructor which lets us translate text outside of a component.
// Note that we do not load translations at this point, so we can avoid shipping unused translations to
// clients. The translations are added when the app is booting and we know exactly which locale to load.
// eslint-disable-next-line import/no-mutable-exports
export let intl = createIntl({ locale: defaultLocale, messages: {} }, intlCache);

export const updateIntl = (locale: Locale, messages: IntlMessages) => {
	intl = createIntl({ locale, messages }, intlCache);
};

type LocaleProviderProps = {
	locale?: Locale;
	translations?: IntlMessages[];
};

const LocaleProvider: FCWithChildren<LocaleProviderProps> = ({
	locale: overrideLocale,
	translations,
	children,
}) => {
	const [loading, setLoading] = useState(!translations);

	const detectedLocale = getLocale();
	const locale = overrideLocale ?? detectedLocale;

	// For tests, render() passes in translations. For prod, the translations are dynamically loaded
	if (translations?.[0]) {
		updateIntl(locale, translations[0]);
	}

	useEffect(() => {
		/**
		 * Import language translations dynamically at runtime
		 */
		const loadTranslations = async (tenant: string) => {
			setLoading(true);

			const data = (await import(
				`../../../locale/${tenant}/${locale}.ri.compiled.json`
			)) as { default: IntlMessages };

			updateIntl(locale, data.default);

			setLoading(false);
		};

		if (!translations) {
			void loadTranslations(getConfig("tenant"));
		}
	}, [translations, locale]);

	/**
	 * Store the new locale in our authoritative store, then reload the page so the new locale is used
	 */
	const changeLocale = (newLocale: Locale) => {
		setItem(localeStorageKey, newLocale);
		window.location.reload();
	};

	const contextValue = useMemo(() => ({ locale, changeLocale }), [locale]);

	return (
		<LocaleContext.Provider value={contextValue}>
			{loading ? (
				<DelayedRender delay={250}>
					<FullScreenCircularProgress />
				</DelayedRender>
			) : (
				<RawIntlProvider value={intl}>{children}</RawIntlProvider>
			)}
		</LocaleContext.Provider>
	);
};

function useLocale() {
	const context = useContext(LocaleContext);

	if (!context) {
		throw new Error("useLocale must be used within a context");
	}

	return context;
}

export { LocaleProvider, useLocale };
