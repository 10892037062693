import type { MemberPortalConfig } from "../../declarations";

export function getConfig<T extends keyof MemberPortalConfig>(
	configName: T,
): MemberPortalConfig[T] {
	if (typeof AppConfig !== "undefined" && typeof BuildConfig !== "undefined") {
		const Config = { ...AppConfig, ...BuildConfig };
		if (configName in Config) {
			return Config[configName];
		}
	}

	throw new Error(`Unknown config: ${configName}`);
}
