import { pxToRem } from "@getbread/dough";
import { alpha, styled } from "@mui/material/styles";

export const ErrorMessageContainer = styled("div")`
	width: 100%;
	font-size: ${pxToRem(14)};
	line-height: ${pxToRem(20)};
	background-color: ${({ theme }) => alpha(theme.palette.error.main, 0.15)};
	padding: var(--space-s);
	display: flex;
	align-items: center;
	border-radius: 2px;
	white-space: pre-wrap;
	margin-bottom: var(--space-m);

	.MuiSvgIcon-root {
		margin-right: var(--space-s);
	}
`;
