import {
	getGetBuyerQueryKey,
	useUpdateBuyer as useApiClientUpdateBuyer,
	useUpdateBuyerContact as useApiClientUpdateBuyerContact,
} from "@getbread/api-client-buyer-v2";
import { useQueryClient } from "@tanstack/react-query";

export const useUpdateBuyer = () => {
	const queryClient = useQueryClient();

	return useApiClientUpdateBuyer({
		mutation: {
			onSuccess: async ({ data: updatedBuyer }) =>
				queryClient.invalidateQueries({ queryKey: getGetBuyerQueryKey(updatedBuyer.id) }),
		},
	});
};

export const useUpdateBuyerContact = () => {
	const queryClient = useQueryClient();

	return useApiClientUpdateBuyerContact({
		mutation: {
			onSuccess: async ({ data: updatedBuyer }) =>
				queryClient.invalidateQueries({
					queryKey: getGetBuyerQueryKey(updatedBuyer.buyerID!),
				}),
		},
	});
};
