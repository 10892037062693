import { useLocale } from "../components/LocaleProvider";
import { getConfig } from "./getConfig";

const themeName = getConfig("themename");

const usePublicPath = () => {
	return themeName === "threeDotDough" || themeName === "breadTheme" ? "/bread" : "/payPlan";
};

const useLocalePublicPath = () => {
	const { locale } = useLocale();
	const publicPath = usePublicPath();
	return `${publicPath}/${locale}`;
};

export { usePublicPath, useLocalePublicPath };
